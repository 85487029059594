import React from 'react';
import logo from './svg/logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="Top">
        Berlin
      </div>
      <div className="Center">
        <div className="Coming-soon Left">
          Coming Soon
        </div>
        <div className="Info">
          <img src={logo} className="App-logo" alt="logo" />
          <div className="Title">
              MIKESIAN
          </div>
          <div className="Contact">
            <span className="Email">
              <a
                  className="App-link"
                  href="mailto:hello@mikesian.studio"
              >
                HELLO@MIKESIAN.STUDIO
              </a>
            </span>
            <span className="Text">
              DESIGN &amp; ART
            </span>
            <span className="Phone">
              <a
                className="App-link"
                href="tel:+447715695555"
              >
                 +44 7715 695555
              </a>
            </span>
          </div>
        </div>
        <div className="Coming-soon Right">
          Coming Soon
        </div>
      </div>
      <div className="Bottom">
        Manchester
      </div>
    </div>
  );
}

export default App;
